{
    class AflProjectsTabController {
        static get $inject() {
            return ['routesConstant', '$state'];
        }

        constructor(routesConstant, $state) {
            this.CUSTOMER = routesConstant.CUSTOMER;
            this.$state = $state;
        }

        isReportDisabled = () => false; // REMOVING AEROS-3651

        areResultsDisabled = () => false; // REMOVING AEROS-3686

        dataExists = () => this.toolsDataExists['All'];

        reportClick = () => {
            if (!this.isReportDisabled()) {
                this.$state.go(this.CUSTOMER.PROJECTS_PROJECT_REPORT.stateName);
            }
        };

        resultsClick = () => {
            // REMOVING AEROS-3686
            // if (!this.areResultsDisabled()) {
                this.$state.go(this.CUSTOMER.PROJECTS_PROJECT_RESULTS.stateName, {id: this.project.id,
                    testType: 'summary', resultType: 'All'});
            //}
        };

        isActiveState = (state) => this.$state.current.name === state;
    }


    angular.module('aerosApp')
        .component('aflProjectsTab', {
            templateUrl: '/static/javascript/directives/afl-projects-tab/afl-projects-tab.html',
            controller: AflProjectsTabController,
            bindings: {
                project: '=',
                toolsDataExists: '=',
                allow: '=',
                getTeam: '&',
                removeMe: '&'
            }
        })
}
